import * as React from "react"
import { Card, Radio } from "antd"
import "./product-overview.css"
import { GetString } from "./string-provider"
import { GatsbyImage } from "gatsby-plugin-image"

interface FilterBlockProps {
  cover: any
  title: string
  data: string[]
  value: string
  onValueChange: (newValue: string) => void
  subFilter?: FilterBlockProps
}

export const FilterBlock: React.FC<FilterBlockProps> = ({
  cover,
  data,
  subFilter,
  value,
  onValueChange,
}) => {
  return (
    <Card
      style={{ marginTop: 2 }}
      cover={
        <div
          style={{
            width: "60px",
            margin: "5px auto",
            borderRadius: "10px",
          }}
        >
          <GatsbyImage style={{}} image={cover} alt={GetString(value)} />
        </div>
      }
    >
      <Card.Meta
        description={
          <>
            <Radio.Group
              defaultValue="all"
              size="small"
              value={value}
              onChange={e => {
                onValueChange(e.target.value)
              }}
            >
              <Radio.Button style={{ margin: "3px" }} value="all">
                {GetString("all")}
              </Radio.Button>
              {data.map(current => (
                <Radio.Button
                  style={{ margin: "3px" }}
                  key={current}
                  value={current}
                >
                  {GetString(current)}
                </Radio.Button>
              ))}
            </Radio.Group>
            {subFilter && (
              <Radio.Group
                defaultValue="all"
                size="small"
                value={subFilter.value}
                onChange={e => {
                  subFilter.onValueChange(e.target.value)
                }}
              >
                <Radio.Button style={{ margin: "3px" }} value="all">
                  {GetString("all")}
                </Radio.Button>
                {subFilter.data.map(current => (
                  <Radio.Button
                    style={{ margin: "3px" }}
                    key={current}
                    value={current}
                  >
                    {GetString(current)}
                  </Radio.Button>
                ))}
              </Radio.Group>
            )}
          </>
        }
      />
    </Card>
  )
}
