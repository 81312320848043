import * as React from "react"
import {
  Radio,
  Row,
  Col,
  Affix,
  Select,
  Tag,
  Card,
  Badge,
  Collapse,
  Button,
  Result,
  Divider,
  Carousel,
} from "antd"
import Layout from "../components/layout"
import SEO from "../components/seo"
import watchesData from "./watches.json"
import { ProductOverview, ProductData } from "../components/product-overview"
import { useStaticQuery, graphql } from "gatsby"
import { Header } from "../components/header"
import { GetString } from "../components/string-provider"
import { FilterBlock } from "../components/filter-block"
import { GatsbyImage } from "gatsby-plugin-image"
import { CaretUpFilled, CaretDownFilled } from "@ant-design/icons"
import { ProductDetails } from "../components/product-details"
import { navigate } from "gatsby"

enum CaseVariants {
  all = "all",
  silver = "silver",
  black = "black",
  gold = "gold",
}
enum BackgroundVariants {
  all = "all",
  silver = "silver",
  black = "black",
  gold = "gold",
}
enum FiguresVariants {
  all = "all",
  silver = "silver",
  black = "black",
  gold = "gold",
}
enum ClockwiseVariants {
  all = "all",
  silver = "silver",
  black = "black",
  gold = "gold",
}
enum BandVariants {
  all = "all",
  metal = "metal",
  leather = "silver",
}
enum BandColorVariants {
  all = "all",
  silver = "silver",
  black = "black",
  gold = "gold",
}
interface FormState {
  CASE: CaseVariants
  BACKGROUND: BackgroundVariants
  FIGURES: FiguresVariants
  CLOCKWISE: ClockwiseVariants
  BAND: BandVariants
  BAND_COLOR: BandColorVariants
}

const IndexPage = () => {
  const BAND_COLOR = ["brown", "black", "silver", "gold"]
  const BAND = ["leather", "metal"]
  const CASE = ["silver", "black", "gold"]
  const CLOCKWISE = ["standard", "blue", "orange", "red"]
  const FIGURES = ["black", "blue", "orange", "red"]
  const BACKGROUND = ["black", "white"]

  const defaultState: FormState = {
    BACKGROUND: BackgroundVariants.all,
    CASE: CaseVariants.all,
    FIGURES: FiguresVariants.all,
    CLOCKWISE: ClockwiseVariants.all,
    BAND: BandVariants.all,
    BAND_COLOR: BandColorVariants.all,
  }
  const [formState, setFormState] = React.useState<FormState>(defaultState)
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { in: ["jpg", "png"] } }) {
        edges {
          node {
            publicURL
            name
            childImageSharp {
              gatsbyImageData(
                width: 220
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      largeImages: allFile(filter: { extension: { in: ["jpg", "png"] } }) {
        edges {
          node {
            publicURL
            name
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    fragment FluidCover on ImageSharp {
      fluid(maxWidth: 400, maxHeight: 250) {
        src
      }
    }
  `)

  const [filteredWatchesData, setFilteredWatchesData] = React.useState(
    watchesData
  )
  const [pictures, setPictures] = React.useState({})
  const [largePictures, setLargePictures] = React.useState({})
  React.useEffect(() => {
    if (data.allFile.edges) {
      const images = {}
      data.allFile.edges.map(file => {
        images[file.node.name] = file.node.childImageSharp.gatsbyImageData
      })
      setPictures(images)
      const largeImages = {}

      data.largeImages.edges.map(file => {
        largeImages[file.node.name] = file.node.childImageSharp.gatsbyImageData
      })
      setLargePictures(largeImages)
    }
  }, [data])
  React.useEffect(() => {
    const filtered = watchesData.filter(current => {
      if (
        (formState.BAND === current.band ||
          formState.BAND === BandVariants.all) &&
        (formState.BAND_COLOR === current.bandColor ||
          formState.BAND_COLOR === BandColorVariants.all) &&
        (formState.CASE === current.case ||
          formState.CASE === CaseVariants.all) &&
        (formState.FIGURES === current.figures ||
          formState.FIGURES === FiguresVariants.all) &&
        (formState.BACKGROUND === current.background ||
          formState.BACKGROUND === BackgroundVariants.all) &&
        (formState.CLOCKWISE === current.clockwise ||
          formState.CLOCKWISE === ClockwiseVariants.all)
      ) {
        return true
      }
      return false
    })
    setFilteredWatchesData(filtered)
  }, [formState])

  const [filterClosed, setFilterClosed] = React.useState(false)
  const [visible, setVisible] = React.useState(false)
  const [displayedProduct, setDisplayedProduct] = React.useState<
    ProductData | undefined
  >(undefined)
  React.useEffect(() => {
    if (displayedProduct) {
      setVisible(true)
      navigate(`#?product=${displayedProduct.id}`)
    }
  }, [displayedProduct])
  return (
    <Layout>
      {displayedProduct && (
        <ProductDetails
          visible={visible}
          cover={largePictures[displayedProduct.id]}
          onExit={() => {
            setDisplayedProduct(undefined)
            setVisible(false)
          }}
          product={displayedProduct}
        />
      )}
      <Header />
      <div style={{ background: "rgb(0 0 0 / 65%)" }}>
        <SEO title="Accueil" lang="fr" />
        <Row
          gutter={[0, 8]}
          style={{
            background: "rgb(0 0 0 / 65%)",
            margin: "10px 0",
            padding: "10px",
          }}
        >
          <Col lg={18} md={18} sm={24} xs={24} style={{}}>
            <h1>Votre montre, votre style.</h1>
            <h4>
              Affirmez <b>votre personnalité</b> grâce aux dizaines de
              combinaisons de <b>personnalisation</b> à votre disposition.
            </h4>
            <div>
              <div>Montre pour Homme analogique à quartz</div>
              <div>
                Choisissez votre bracelet en cuir ou en métal, 4 couleurs
                disponibles.
              </div>
              <div>Boitier en acier, doré, argenté ou noir.</div>
              <div>Cadran noir ou blanc.</div>
              <div>
                Aiguilles et chiffres personnalisables en rouge, bleu, orange ou
                gris.
              </div>
              <br />
              <div>Diamètre du boitier: 40mm</div>
              <div>Longueur du bracelet: 230mm</div>
              <div>Largeur du bracelet: 20mm</div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={24} xs={24}>
            <Carousel
              autoplay
              autoplaySpeed={3000}
              pauseOnDotsHover
              focusOnSelect
              afterChange={() => console.log()}
              style={{ width: "100%" }}
              dotPosition={"bottom"}
              dots={{ className: "carousel-dots" }}
            >
              <div>
                <GatsbyImage
                  style={{ width: "100%" }}
                  image={largePictures["insitu"]}
                  alt="Mise en contexte"
                />
              </div>
              <div>
                <GatsbyImage
                  style={{ width: "100%" }}
                  image={largePictures["insitu_1"]}
                  alt="Mise en contexte"
                />
              </div>
              <div>
                <GatsbyImage
                  style={{ width: "100%" }}
                  image={largePictures["insitu_2"]}
                  alt="Mise en contexte"
                />
              </div>
              <div>
                <GatsbyImage
                  style={{ width: "100%" }}
                  image={largePictures["insitu_3"]}
                  alt="Mise en contexte"
                />
              </div>
            </Carousel>
          </Col>
        </Row>
        <Divider />
        <Row gutter={[8, 0]}>
          <Col lg={6} md={6} sm={6} xs={24}>
            <Affix offsetTop={10}>
              <div style={{ background: "#000000d6" }}>
                <h4 style={{ paddingTop: "10px" }}>Filtres</h4>
                {!filterClosed && (
                  <Collapse
                    defaultActiveKey={["BAND"]}
                    accordion
                    style={{ zIndex: 4, background: "#000000d6" }}
                  >
                    {[
                      { name: "BAND", data: BAND },
                      { name: "CASE", data: CASE },
                      { name: "CLOCKWISE", data: CLOCKWISE },
                      { name: "FIGURES", data: FIGURES },
                      { name: "BACKGROUND", data: BACKGROUND },
                    ].map((currentCategory, itr) => (
                      <Collapse.Panel
                        header={
                          <Badge
                            dot={formState[currentCategory.name] !== "all"}
                          >
                            {GetString(currentCategory.name)}
                          </Badge>
                        }
                        key={currentCategory.name}
                      >
                        <FilterBlock
                          title={currentCategory.name}
                          data={currentCategory.data}
                          cover={pictures[currentCategory.name.toLowerCase()]}
                          value={formState[currentCategory.name]}
                          onValueChange={newValue => {
                            setFormState({
                              ...formState,
                              [currentCategory.name]: newValue,
                            })
                          }}
                          subFilter={
                            currentCategory.name === "BAND" && {
                              title: "BAND_COLOR",
                              data: BAND_COLOR,
                              cover:
                                pictures[currentCategory.name.toLowerCase()],
                              value: formState["BAND_COLOR"],
                              onValueChange: newValue => {
                                setFormState({
                                  ...formState,
                                  ["BAND_COLOR"]: newValue as any,
                                })
                              },
                            }
                          }
                        />
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                )}
                <div style={{ zIndex: 4, background: "#000000d6" }}>
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => setFilterClosed(!filterClosed)}
                  >
                    {filterClosed ? <CaretDownFilled /> : <CaretUpFilled />}
                  </Button>
                </div>
              </div>
            </Affix>
          </Col>
          <Col lg={18} md={18} sm={18} xs={24}>
            <Row gutter={[8, 8]}>
              {filteredWatchesData.map(current => (
                <Col xs={12} sm={8} md={8} lg={6} key={current.id}>
                  <ProductOverview
                    onOpen={() => {
                      setDisplayedProduct(current)
                    }}
                    data={current}
                    cover={pictures[current.id]}
                  />
                </Col>
              ))}
            </Row>
            {filteredWatchesData.length === 0 && (
              <Result
                status="404"
                title="Aïe, nous n'avons pas ça en stock!"
                subTitle="On rééssaie?"
                extra={
                  <Button
                    onClick={() => setFormState(defaultState)}
                    type="primary"
                  >
                    Réinitialiser les filtres
                  </Button>
                }
              />
            )}
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default IndexPage
