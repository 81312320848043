import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Badge } from "antd"
import { ShoppingFilled, UserOutlined } from "@ant-design/icons"
import { CartActions, useCart } from "./cart-context"

export const Header = () => {
  const cartContext = useCart()

  React.useEffect(() => {
    if (!cartContext) {
      return
    }
    // @ts-ignore
    const store = Snipcart.store.getState()

    cartContext.dispatch({
      type: CartActions.SetItems,
      payload: store.cart.items.count,
    })
  }, [])

  return (
    <header
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ flexGrow: 1 }}></div>
      <button
        className="snipcart-customer-signin"
        style={{ fontSize: "25px", display: "block" }}
      >
        <UserOutlined style={{ fontSize: "25px" }} />{" "}
      </button>
      <Badge size="small" showZero count={cartContext.state.items}>
        <button
          className="snipcart-checkout"
          style={{ fontSize: "25px", display: "block" }}
        >
          <ShoppingFilled style={{ fontSize: "25px" }} />{" "}
          <span className="snipcart-total-price"></span>
        </button>
      </Badge>
    </header>
  )
}
