const STRINGS = {
  all: "tous",
  leather: "cuir",
  brown: "marron",
  metal: "métal",
  silver: "argenté",
  black: "noir",
  gold: "doré",
  standard: "standard",
  blue: "bleu",
  orange: "orange",
  red: "rouge",
  white: "blanc",
  BAND: "bracelet",
  CASE: "boitier",
  FIGURES: "chiffres",
  BACKGROUND: "fond",
  CLOCKWISE: "aiguilles",
}

export const GetString = (raw: string) => {
  return STRINGS[raw]
}
