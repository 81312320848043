import * as React from "react"
import { Card, Button, Tag, Image as AntdImage, Badge } from "antd"
import { ShoppingCartOutlined } from "@ant-design/icons"
import "./product-overview.css"
import { useCart, CartActions } from "./cart-context"
import { GetString } from "./string-provider"
import { GatsbyImage } from "gatsby-plugin-image"

export interface ProductData {
  id: string | number
  ref: string
  bandColor: string
  band: string
  case: string
  clockwise: string
  figures: string
  background: string
  description?: string
  name?: string
}

interface ProductOverviewProps {
  cover?: any
  data: ProductData
  onOpen: () => void
}

export const ProductOverview: React.FC<ProductOverviewProps> = ({
  cover,
  data,
  onOpen,
}) => {
  const [fullDescription, setFullDescription] = React.useState("Montre de luxe")
  const [title] = React.useState(`Casual Spirit CS#${data.id}`)
  const cartContext = useCart()
  React.useEffect(() => {
    const description = `Montre luxe avec bracelet en ${GetString(
      data.band
    )} ${GetString(data.bandColor)}, fond ${GetString(
      data.background
    )}, aiguilles ${GetString(data.clockwise)}, chiffres ${GetString(
      data.figures
    )} et boitier ${GetString(data.case)}.`
    setFullDescription(description)
  }, [data])
  return (
    <>
      <Badge.Ribbon
        text={<span style={{ textDecoration: "line-through" }}>79.99€</span>}
        color="red"
      >
        <Card
          style={{ cursor: "pointer", width: "100%" }}
          cover={
            <GatsbyImage
              onClick={() => onOpen()}
              style={{}}
              alt={title}
              image={cover}
            />
          }
          actions={[
            <button
              onClick={() => {
                // @ts-ignore
                const store = Snipcart.store.getState()

                cartContext.dispatch({
                  type: CartActions.SetItems,
                  payload: store.cart.items.count + 1,
                })
              }}
              className="snipcart-add-item"
              data-item-id={`${data.ref}`}
              data-item-price="49.99"
              data-item-url="/"
              data-item-description={`${fullDescription}`}
              data-item-image={cover?.src}
              data-item-name={title}
            >
              <ShoppingCartOutlined /> Ajouter au panier
            </button>,
          ]}
        >
          <Card.Meta
            title={
              <span style={{ cursor: "pointer" }} onClick={() => onOpen()}>
                {title}
              </span>
            }
            description={
              <Tag onClick={() => onOpen()}>
                <h4 style={{ marginBottom: 0 }}>49.99€</h4>
              </Tag>
            }
          />
        </Card>
      </Badge.Ribbon>
    </>
  )
}
