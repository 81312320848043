import React from "react"
import { Drawer, Row, Col, Carousel, Button } from "antd"
import { ProductData } from "./product-overview"
import { ShoppingCartOutlined, CloseCircleFilled } from "@ant-design/icons"
import { useCart, CartActions } from "./cart-context"
import { GetString } from "./string-provider"
import { GatsbyImage } from "gatsby-plugin-image"

interface ProductDetailsProps {
  visible: boolean
  onExit: () => void
  product: ProductData
  cover: any
}

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  visible = false,
  onExit,
  product,
  cover,
}) => {
  const [title, setTitle] = React.useState(`Casual Spirit CS#${product?.id}`)
  const [fullDescription, setFullDescription] = React.useState(`Montre Premium`)
  const cartContext = useCart()

  React.useEffect(() => {
    const description = `Montre luxe avec bracelet en ${GetString(
      product.band
    )} ${GetString(product.bandColor)}, fond ${GetString(
      product.background
    )}, aiguilles ${GetString(product.clockwise)}, chiffres ${GetString(
      product.figures
    )} et boitier ${GetString(product.case)}.`
    setFullDescription(description)
    setTitle(`Casual Spirit CS#${product?.id}`)
  }, [product])
  if (!product) {
    return <></>
  }

  return (
    <>
      <Drawer
        // title={<div style={{ maxWidth: 1200, margin: "auto" }}>{title}</div>}
        placement={"bottom"}
        closable={false}
        onClose={onExit}
        closeIcon
        visible={visible}
        contentWrapperStyle={{ maxHeight: "90vh", height: "800px" }}
      >
        <div style={{ maxWidth: 1200, margin: "auto" }}>
          <div
            style={{
              display: "flex",
              alignItems: "top",
              marginBottom: "10px",
            }}
          >
            <h3>{title}</h3>
            <div style={{ flexGrow: 1 }} />
            <div onClick={onExit} style={{ cursor: "pointer" }}>
              <CloseCircleFilled style={{ fontSize: "20px" }} />
            </div>
          </div>
          <Row gutter={[16, 16]}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <Carousel>
                <div>
                  <GatsbyImage style={{}} alt={title} image={cover} />
                </div>
              </Carousel>
            </Col>
            <Col lg={12} md={12} sm={24} xs={24}>
              <div>
                <div>
                  Bracelet: {GetString(product.band)}{" "}
                  {GetString(product.bandColor)}
                </div>
                <div>Cadran: {GetString(product.background)} - 40mm</div>
                <div>Aiguilles: {GetString(product.clockwise)}</div>
                <div>Chiffres: {GetString(product.figures)}</div>
                <div>Boitier: {GetString(product.case)}</div>
              </div>
              <h4>
                <span style={{ textDecoration: "line-through" }}>79,99€</span>{" "}
                49,99€
              </h4>
              <button
                onClick={() => {
                  onExit()
                  // @ts-ignore
                  const store = Snipcart.store.getState()

                  cartContext.dispatch({
                    type: CartActions.SetItems,
                    payload: store.cart.items.count + 1,
                  })
                }}
                className="snipcart-add-item"
                data-item-id={`${product.ref}`}
                data-item-price="79.99"
                data-item-url="/"
                data-item-description={`${fullDescription}`}
                // data-item-image={cover?.src}
                data-item-name={title}
              >
                <ShoppingCartOutlined /> Ajouter au panier
              </button>
            </Col>
          </Row>
        </div>
      </Drawer>
    </>
  )
}
